* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
  color: #333;
}


.div {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 10px;
  text-align: center;
}

h1 {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 32px;
  font-family: "Bungee Shade", sans-serif;
}

.hearts {
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  max-height: 40px;
  font-size: 40px;
  font-family: "Bungee Shade", sans-serif;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to { opacity: 1;}
}

p {
  animation: fadeIn 3s;
  margin-bottom: 20px;
  font-size: 26px;
  width: 100%;
}

input {
  font-size: 30px;
  padding: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}


button {
  font-size: 16px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  margin-bottom: 20px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}


p {
  margin-top: 20px;
}
